<template>
  <section class="notice with-container">
    <h2>魔筷知识产权投诉须知：</h2>
    <h3>一. 受理范围</h3>
    <ul>
      <li>1. 侵犯他人商标权，包括发布销售假冒商品及其他未经授权或超出授权范围在所发布的商品信息中使用他人注册商标的行为。</li>
      <li>2. 侵犯他人著作权，包含但不限于图书、电子书、软件等，在平台发布、销售及其他侵害他人著作权行为。 </li>
      <li>3. 侵犯他人专利权，用户出售的商品或所使用的其他信息侵害他人专利权的行为，包含侵害他人外观设计专利、实用新型专利或发明专利。</li>
      <li>4. 其他依法应受理的相关投诉或其他知识产权投诉内容。</li>
    </ul>
    <h3>二. 不受理范围</h3>
    <ul>
      <li>1. 魔筷尊重权利人与其合作伙伴达成的授权协议。对于协议内容不涉及知识产权侵权范围的，由合约协定双方解决，魔筷不对此进行参与。</li>
    </ul>
    <h3>三. 审查</h3>
    <p>平台在收到权利人提交的材料及保证书后，将对投诉内容及相关材料进行审查：</p>
    <ul>
      <li>1. 对于符合要求的投诉平台予以受理；对于资质不符、材料不全或其他不符合要求的情况，不予受理，直至投诉人补充完整资料。</li>
      <li>2. 平台在审核投诉材料时，将通知被投诉人进行申诉或整改，被投诉人限期内未提交申诉材料的，不影响平台审核。</li>
      <li>3. 审核通过的，平台将依据相关法律法规冻结被投诉商品及店铺进行中及未进行的相关服务。</li>
    </ul>
    <h3>四. 处理时效</h3>
    <ul>
      <li>1. 投诉人在成功提交投诉后的3个工作日内，魔筷将给予反馈。</li>
    </ul>
    <div 
      :class="disable ? 'btn disable' : 'btn'"
      @click="gotoComplaint"
    >
      {{ btnText }}
    </div>
  </section>
</template>

<script>
export default {
  name: 'IntellectualNotice',
  data() {
    return {
      countdown: 10,
      timer: null,
    }
  },
  computed: {
    btnText() {
      return this.countdown ? `我已仔细阅读投诉须知(${this.countdown}s)` : '我要投诉';
    },
    disable() {
      return this.countdown > 0;
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.countdown --;
      this.countdown <= 0 && this.clearInterval();
    }, 1000);
  },
  methods: {
    clearInterval() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    gotoComplaint() {
      if (this.disable) return;
      this.$router.push('complaint');
    }
  }
}
</script>

<style lang="less" scoped>
  .notice {
    margin-top: 60px;
    padding-bottom: 190px;
  }
  h2 {
    margin: 0 auto 50px;
    text-align: center;
    line-height: 37px;
    color: #333;
    font-size: 26px;
    font-weight: 500;
  }
  h3 {
    margin-top: 30px;
    margin-bottom: 12px;
    line-height: 30px;
    font-size: 22px;
    font-weight: 500;
    color: #333;
  }
  ul li {
    line-height: 32px;
    font-size: 16px;
    color: #333;
  }
  .btn {
    width: 300px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    margin: 60px auto 0;
    background: #ff6000;
    border-radius: 2px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    &.disable {
      background: #f7f7f7;
      border: 1px solid #ededed;
      color: #bbb;
    }
  }
</style>

